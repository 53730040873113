<template>
  <b-modal
    :id="uuid"
    :title="$t('scheduler.modal.viewer.title')"
    ok-only
    :ok-title="$t('compiled.modal.script.close')"
    centered
    size="lg"
  >
    <b-container fluid>
      <b-row>
        <b-col class="d-flex justify-content-between">
          <h2>{{ $t(`scheduler.modal.viewer.${i18nKey}.title`) }}</h2>
          <b-button
            v-clipboard:copy="script"
            v-clipboard:success="onCopy"
            class="mb-1"
            size="sm"
            variant="outline-purple"
          >
            <b-icon
              icon="files"
            />
            {{ $t('scheduler.modal.viewer.copy') }}
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <prism-editor id="prism-editor" class="my-editor height-500" v-model="script" :highlight="highlighter" line-numbers></prism-editor>
        </b-col>
      </b-row>
    </b-container>

  </b-modal>
</template>

<script>
import { BModal, BContainer, BCol, BRow, BButton, BIcon } from 'bootstrap-vue'

import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere

import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-markup.js';
import 'prismjs/components/prism-xml-doc.js';
import 'prismjs/components/prism-json.js';
import 'prismjs/themes/prism-tomorrow.css';
import { makeToast } from '@/layouts/components/Popups'

  export default {
    components: {
      BIcon,
      BModal,
      BContainer, 
      BCol, 
      BRow, 
      BButton,
      PrismEditor
    },
    props: {
      uuid: {
        type: String,
        required: true,
      },
      script: {
        type: String,
        default: () => {}
      },
      i18nKey: {
        type: String,
        default: 'body'
      }
    },
    data() {
      return { 
        scriptInfo: null,
      }
    },
    computed: {
      isJson() {
          try {
            JSON.parse(this.script);
          } catch (e) {
              return false;
          }
          return true;
      },
      isXml() {
        var domParser = new DOMParser();
        var dom = domParser.parseFromString(this.script, 'text/xml');

        if(dom.documentElement.nodeName != 'parsererror') {
          return true
        } 
        return false
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        this.scriptInfo = this.isJson ? JSON.parse(this.script) : this.script
      },
      onCopy() {
          makeToast({
            title: this.$t(`scheduler.modal.script_viewer.copy.title`),
            text: this.$t(`scheduler.modal.script_viewer.copy.message`),
            variant: "success",
            icon: "FileTextIcon",
          })
      },
      highlighter(code) {
        let lang = null
        switch(true) {
          case this.isJson: {
            lang = languages.json
          } break;
          case this.isXml: {
            lang = languages.xml
          }
          default: {
            lang = languages.plain
          }
        }
        
        return highlight(code, lang);
      },
    }
  }
</script>

<style lang="scss" scoped>

</style>