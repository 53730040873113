<template>
  <b-container class="p-0" fluid>
    <b-row>
      <b-col md="12">
        <b-card v-if="schedulerInfo">
          <b-card-body>
            <b-row>
              <b-col md="12">
                <div class="d-flex justify-content-between">
                  <div>
                    <h1 class="d-inline-block">
                      {{ schedulerInfo.name }}
                    </h1>
                    <default-changer
                      class="d-inline-block ml-50"
                      :busy="true"
                      i18nKey="scheduler.name"
                      field="name"
                      action="patchScheduler"
                      type="input"
                      v-model="schedulerInfo.name"
                      :aditionalData="{ schedulerID: schedulerInfo.id, transmissionID }"
                    />
                  </div>
                  <div>
                    <b-button
                      title="Execution Logs"
                      pill
                      class="mr-2"
                      size="sm"
                      :variant="visibleExecutionLog ? 'outline-warning' : 'outline-purple'"
                      @click="toggleExecutionLog"
                    >
                      <b-icon
                        :icon="visibleExecutionLog ? 'file-earmark-break' : 'bar-chart' "
                      />
                      <span class="font-weight-bolder">
                        {{ visibleExecutionLog ? $t('scheduler.view.parameters') : $t('scheduler.view.dashboard') }}
                      </span>
                    </b-button>
                    <b-button
                      title="Publish Version"
                      :disabled="loadingPublish || userCantEdit"
                      pill
                      class="mr-2"
                      size="sm"
                      variant="outline-info"
                      @click="openPublishVersion"
                    >
                      <b-icon v-if="!loadingPublish" icon="file-earmark-check"/>
                      <b-spinner v-else variant="light" small/>
                      <span class="font-weight-bolder">
                        {{ $t('scheduler.view.publish') }}
                      </span>
                      <publish-version-modal
                        :uuid="getID('publish-version')"
                        v-model="versionName"
                        i18nKey="publish_version"
                        @submit="publishVersion"
                      />
                    </b-button>
                    <b-button
                      title="Delete this schedule"
                      :disabled="loadingDelete || userCantEdit"
                      pill
                      class="delete-button"
                      size="sm"
                      variant="outline-danger"
                      @click="deleteSchedule"
                      >
                        <b-icon v-if="!loadingDelete" icon="trash"/>
                        <b-spinner v-else variant="light" small/>
                        <span class="font-weight-bolder">
                          {{ $t('common.terms.delete') }}
                        </span>
                    </b-button>
                  </div>
                </div>
                <div class="mt-50">
                  <b-button
                    :id="getID('active')"
                    v-if="!loadingActive"
                    class="text-uppercase mr-50 cursor-pointer badge-toggler py-25 px-0"
                    :variant="schedulerInfo.active ? hasVersions ? 'success' : 'warning' : 'secondary'"
                    @click="toggleActive(schedulerInfo)"
                    :disabled="userCantEdit"
                    >
                    <b-icon
                      :icon="schedulerInfo.active ? 'stop-circle' : 'play-circle'"
                    />
                    {{ schedulerInfo.active ? hasVersions ? $t('scheduler.view.active') : $t('scheduler.view.active')+'*' : $t('scheduler.view.inactive') }}
                  </b-button>
                  <b-badge :variant="schedulerInfo.active ? 'success' : 'secondary'" v-else class="badge-toggler mr-50 badge-padding-fix">
                    <b-spinner variant="light" small />
                  </b-badge>
                  <b-tooltip
                    triggers="hover"
                    :target="getID('active')"
                    title="Publish a version, otherwise this wil not work"
                    variant="black"
                  />
                  <b-button
                    v-if="!loadingFavorite"
                    class="text-uppercase mr-50 cursor-pointer badge-toggler py-25 px-0"
                    :variant="schedulerInfo.favorite ? 'favorite' : 'secondary'"
                    @click="toggleFavorite"
                    :disabled="userCantEdit"
                  >
                    <b-icon
                      :icon="schedulerInfo.favorite ? 'star-fill' : 'star'"
                    />
                    {{ $t('scheduler.view.favorite') }}
                  </b-button>
                  
                  <b-badge :variant="schedulerInfo.favorite ? 'favorite' : 'secondary'" v-else class="badge-toggler badge-padding-fix mr-50">
                    <b-spinner variant="light" small />
                  </b-badge>
                  <b-badge
                    v-if="versionsLoaded"
                    class="mr-50 cursor-pointer version-badge py-50"
                    :variant="hasVersions ? 'info' : 'light-danger'"
                    @click="toggleVersions"
                  >
                    <div v-if="hasVersions">
                      <b-icon
                        icon="eye-fill"
                      />
                      <span class="text-uppercase">
                        {{ $t('scheduler.view.version') }}:
                      </span>
                      {{ currentVersion.name }}
                    </div>
                    <span v-else>
                      <b-icon
                        icon="exclamation-triangle"
                      />
                      No versions published 
                    </span>

                    
                  </b-badge>
                  <b-badge variant="secondary" v-else class="badge-toggler badge-padding-fix">
                    <b-spinner variant="light" small />
                  </b-badge>
                  <versions-view-modal
                    v-if="hasVersions"
                    :ref="getID('versions-list')"
                    :uuid="getID('versions-list')"
                    :transmissionID="transmissionID"
                    :schedulerID="schedulerInfo.id"
                    :currentVersion="currentVersion"
                    @getCurrentVersion="getCurrentVersion"
                  />
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-2">    
              <b-col md="4" v-if="!scheduleType">
                <p class="p-0 text-muted small m-0">{{ $t('scheduler.view.event') }}</p>
                <strong class="p-0">
                  {{ schedulerInfo.event.identifier }}
                </strong>
              </b-col>
              <b-col md="4" v-if="!scheduleType">
                <p class="p-0 text-muted small m-0">{{ $t('scheduler.view.service') }}</p>
                <strong class="p-0">
                  {{ schedulerInfo.service.identifier }}
                </strong>
              </b-col>
              <b-col md="8" v-if="scheduleType">
               <p class="p-0 text-muted small m-0">{{ $t('scheduler.view.webhook') }}</p>
                <strong class="p-0">
                  {{ schedulerInfo.webhook.identifier }}
                </strong>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col md="4">
                <p class="p-0 text-muted small m-0">{{ $t('scheduler.view.created_via') }}</p>
                <strong class="p-0">
                  {{ schedulerInfo.enum_creation_origin.label }}
                </strong>
              </b-col>
              <b-col md="4">
                <p class="p-0 text-muted small m-0">{{ $t('scheduler.view.start_date') }}</p>
                <strong>
                  {{ formatDate(schedulerInfo.start_date) }}
                </strong>
                <default-changer
                  class="d-inline-block ml-50"
                  
                  i18nKey="scheduler.start_date"
                  field="start_date"
                  action="patchScheduler"
                  type="datepickr"
                  v-model="schedulerInfo.start_date"
                  :aditionalData="{ schedulerID: schedulerInfo.id, transmissionID }"
                  :config="startDatePickrConfig"
                />
              </b-col>
              <b-col md="4">
                <p class="p-0 text-muted small m-0">{{ $t('scheduler.view.end_date') }}</p>
                <strong>
                  {{ formatDate(schedulerInfo.end_date) || '&#8734;' }}
                </strong>
                <default-changer
                  class="d-inline-block ml-50"

                  i18nKey="scheduler.end_date"
                  field="end_date"
                  action="patchScheduler"
                  type="datepickr"
                  v-model="schedulerInfo.end_date"
                  :aditionalData="{ schedulerID: schedulerInfo.id, transmissionID }"
                  :config="endDatePickrConfig"
                />
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col md="4">
                <div>
                  <span class="p-0 text-muted small m-0">{{ $t('scheduler.view.last_execution_at') }}</span>
                  <helper-tooltip class="ml-50" :innerText="$t('scheduler.view.helper.last_execution_at')"/>
                </div>
                <strong>
                  {{ formatDate(schedulerInfo.last_execution_at) || $t('scheduler.view.not_executed_yet') }}
                </strong>
                
              </b-col>
              <b-col md="4">
                <p class="p-0 text-muted small m-0">{{ $t('common.terms.created_at') }}</p>
                <strong>
                  {{ formatDate(schedulerInfo.created_at) }}
                </strong>
              </b-col>
              <b-col md="4">
                <p class="p-0 text-muted small m-0">{{ $t('common.terms.updated_at') }}</p>
                <strong>
                  {{ formatDate(schedulerInfo.updated_at) }}
                </strong>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <b-skeleton v-else class="my-2" height="272.3px" width="1589px"/>
        <b-card class="b-card-info" v-if="schedulerInfo && !visibleExecutionLog">
          <b-row class="d-flex justify-content-between">
            <b-col md="3" v-if="scheduleType">
              <p class="p-0 text-muted small m-0">{{ $t('scheduler.view.path') }}</p>
              <strong class="p-0">
                {{ schedulerInfo.path }}
              </strong>
              <default-changer
                class="d-inline-block"
                :class="schedulerInfo.path ? 'ml-50' : ''"

                i18nKey="scheduler.path"
                field="path"
                action="patchScheduler"
                type="input"
                v-model="schedulerInfo.path"
                :aditionalData="{ schedulerID: schedulerInfo.id, transmissionID }"
              />
            </b-col>
            <b-col md="3" v-if="scheduleType">  
              <p class="p-0 text-muted small m-0">{{ $t('scheduler.view.method') }}</p>
              <strong class="p-0">
                {{ schedulerInfo.enum_metodo.label }}
              </strong>
              <default-changer
                class="d-inline-block ml-50"

                i18nKey="scheduler.method"
                field="enum_metodo"
                action="patchScheduler"
                type="select"
                :settedOptions="methods"
                v-model="schedulerInfo.enum_metodo"
                :aditionalData="{ schedulerID: schedulerInfo.id, transmissionID }"
              />
            </b-col>
            <b-col md="3">  
              <p class="p-0 text-muted small m-0">{{ $t('scheduler.view.interval') }}</p>
              <strong class="p-0">
                {{ schedulerInfo.minutes_interval }} {{ $t('scheduler.view.minutes') }}
              </strong>
              <default-changer
                class="d-inline-block ml-50"

                i18nKey="scheduler.minutes_interval"
                field="minutes_interval"
                action="patchScheduler"
                type="input"
                :config="{
                  numericOnly: true,
                  blocks: [2, 2, 2, 0],
                  delimiters: ['d ', 'h ', 'm ']
                }"
                :formatter="minuteToDay"
                :unformatter="dayToMinute"
                v-model="schedulerInfo.minutes_interval"
                :aditionalData="{ schedulerID: schedulerInfo.id, transmissionID }"
              />
            </b-col>
              <b-col md="3">
                <p class="p-0 text-muted small m-0">{{ $t('scheduler.view.debug_mode') }} ?</p>
                <b-form-checkbox
                  switch
                  class="custom-control-success mt-25"
                  v-model="schedulerInfo.debug_mode_active"
                  @change="toggleDebugMode"
                >
                  <span class="switch-icon-left">
                    <b-icon
                      icon="bug"
                    />
                  </span>
                  <span class="switch-icon-right">
                     <b-icon
                      icon="bug"
                    />
                  </span>
                </b-form-checkbox>
              </b-col>
          </b-row>
          <b-row>
            <b-col v-if="!scheduleType">
              <div class="d-flex justify-content-end">
                <b-button  
                  size="sm" 
                  :variant="bodyVisible ? 'outline-info' : 'outline-secondary'" 
                  class="py-25 px-50 mr-1 mb-1"
                  @click="toggleBodyVisibility()"
                >
                  <b-icon
                    :icon="bodyVisible ? 'eye' : 'eye-slash'"
                  />
                  Toggle Visibility
                </b-button>
              </div>
              <b-col md="12" v-if="!scheduleType" class="animate-opt animate__animated animate__fadeInUp animate__faster">
                <b-form-group
                  class="w-100"
                >
                  <json-editor id="detect-json" :class="bodyVisible ? '' : 'body-filter'" key="detect-json" v-model="body" :modes="[ 'code', 'tree', 'preview' ]" height="400px"/>
                </b-form-group>
              </b-col>
            </b-col>
            <b-col v-else>
              <b-tabs class="mt-2" justified>
                <b-tab :title=" $t('scheduler.view.body')">
                  <div class="d-flex justify-content-end">
                    <b-button  
                      size="sm" 
                      :variant="bodyVisible ? 'outline-info' : 'outline-secondary'" 
                      class="py-25 px-50 mr-1 mb-1"
                      @click="toggleBodyVisibility()"
                    >
                      <b-icon
                        :icon="bodyVisible ? 'eye' : 'eye-slash'"
                      />
                      Toggle Visibility
                    </b-button>
                  </div>
                  <b-col md="12">
                    <b-form-group
                      class="w-100"
                    >
                      <prism-editor id="prism-editor" :class="bodyVisible ? '' : 'body-filter'" class="my-editor height-300" v-model="body" :highlight="highlighter" line-numbers></prism-editor>
                    </b-form-group>
                  </b-col>
                  <b-col class="d-flex justify-content-end">
                    <b-button
                      @click="patchBody"
                      variant="success"
                      :disabled="userCantEdit"
                    >
                      {{ $t('common.terms.save')}}
                    </b-button>
                  </b-col>
                </b-tab>
                <b-tab v-if="scheduleType" :title="$t('scheduler.view.headers')">
                  <b-row class="m-1">
                    <b-col class="d-flex justify-content-end">
                      <b-button variant="success" size="sm" @click="toggleAddItem('scheduler-header')" :disabled="userCantEdit" >
                        + {{ $t('scheduler.sidebar.headers.add_item') }}
                      </b-button>
                    </b-col>
                  </b-row>
                  <view-info-table
                    i18nKey="scheduler_header"
                    :items="schedulerInfo.headers"
                    :fields="fields"
                    :editableFields="[ { name: 'field_key', key: 'field_key'}, { name: 'field_value', key: 'field_value'}]"
                    editAction="patchSchedulerWebhookInfo"
                    :aditionalData="{ schedulerID: schedulerInfo.id, transmissionID, type: 'scheduler_header' }"

                    @deleteItem="deleteItem"
                  />
                  <add-list-info
                    :uuid="getID('scheduler-header-sidebar')"
                    i18nKey="headers"
                    :initialValue="{ key: '', value: '' }"
                    @addItems="(items) => { addItems(items, 'insertSchedulerHeader') }"
                    @close="toggleAddItem('scheduler-header')"
                  />
                </b-tab>
                <b-tab v-if="scheduleType" :title="$t('scheduler.view.queries')">
                  <b-row class="m-1">
                    <b-col class="d-flex justify-content-end">
                      <b-button variant="success" size="sm" @click="toggleAddItem('scheduler-query')" :disabled="userCantEdit">
                        + {{ $t('scheduler.sidebar.queries.add_item') }}
                      </b-button>
                    </b-col>
                  </b-row>
                  <view-info-table
                    i18nKey="scheduler_query"
                    :items="schedulerInfo.queries"
                    :fields="fields"
                    :editableFields="[ { name: 'field_key', key: 'field_key'}, { name: 'field_value', key: 'field_value'}]"
                    editAction="patchSchedulerWebhookInfo"
                    :aditionalData="{ schedulerID: schedulerInfo.id, transmissionID, type: 'scheduler_query' }"

                    @deleteItem="deleteItem"
                  />
                    <add-list-info
                    :uuid="getID('scheduler-query-sidebar')"
                    i18nKey="queries"
                    :initialValue="{ key: '', value: '' }"
                    @addItems="(items) => { addItems(items, 'insertSchedulerQuery') }"
                    @close="toggleAddItem('scheduler-query')"
                  />
                </b-tab>
              </b-tabs>
            </b-col>
          </b-row>
        </b-card>
        <b-card v-else-if="visibleExecutionLog">
          <schedule-dashboard
            :transmissionID="transmissionID"
            :schedulerID="schedulerID"
            scrollContainer="body"
          />
        </b-card>
        <b-skeleton v-else class="my-3" height="272.3px" width="1589px"/>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { 
  BContainer, 
  BRow, 
  BCol, 
  BCard, 
  BCardBody, 
  BTabs, 
  BTab, 
  BFormGroup, 
  BFormInput, 
  BFormSelect, 
  BButton, 
  BTable, 
  BSkeleton, 
  BBadge, 
  BSpinner,
  BFormCheckbox,
  BTooltip
} from 'bootstrap-vue'

import ScheduleDashboard from '@/layouts/components/Scheduler/Dashboard.vue'
import AddListInfo from '@/layouts/components/Scheduler/AddListInfo.vue'
import ViewInfoTable from '@/layouts/components/Scheduler/ViewInfoTable.vue'
import PublishVersionModal from '@/layouts/components/Scheduler/PublishVersionModal.vue'
import VersionsViewModal from '@/layouts/components/Scheduler/VersionsViewModal'
import JsonEditor from "@/layouts/components/JsonEditor/JsonEditor.vue";
import Methods from "@/custom/class/Enum/Methods"
import { makeToast } from "@/layouts/components/Popups.js"
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere
import 'animate.css';

import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-markup.js';
import 'prismjs/components/prism-xml-doc.js';
import 'prismjs/components/prism-json.js';
import 'prismjs/themes/prism-tomorrow.css';
import DefaultChanger from '@/layouts/components/DefaultChanger.vue';
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { mapGetters } from 'vuex'
import HelperTooltip from "@/layouts/components/HelperTooltip.vue";

  export default {
    components: {
      BContainer, 
      BRow, 
      BCol, 
      BCard, 
      BCardBody, 
      BTabs, 
      BTab, 
      BFormInput, 
      BFormSelect, 
      BButton, 
      BTable,
      BFormGroup,
      JsonEditor,
      PrismEditor,
      BSkeleton,
      BBadge,
      BSpinner,
      BTooltip,
      DefaultChanger,
      BFormCheckbox,
      PublishVersionModal,
      VersionsViewModal,
      ViewInfoTable,
      AddListInfo,
      ScheduleDashboard,
      HelperTooltip
    },  
    data() {
      return {
        loadingActive: false,
        loadingFavorite: false,
        loadingDelete: false,
        loadingPublish: false,
        bodyVisible: false,
        visibleExecutionLog: 'execution-log',

        uuidMap: {},
        versionName: '',
        currentVersion: null,

        schedulerInfo: null,
        body: null,

        fields: [
          {
            key: "field_key",
            label: "Key",
            sortable: true,
            thStyle: "max-width: 500px;",
          },
          {
            key: "field_value",
            label: "Value",
            sortable: true,
          },
          {
            key: "actions",
            label: "Actions",
            thStyle: "width: 250px;",
          },
        ],

        eventOptions: null,
        serviceOptions: null,
        webhookOptions: null,
      }
    },
    computed: {
      ...mapGetters(['getScheduleVersions']),
      schedulerID() {
        return this.$route.params.schedulerID
      },
      transmissionID() {
        return this.$route.params.transmissionID
      },
      scheduleType() {
        return !!this.schedulerInfo.webhook
      },
      methods() {
        const methods = new Methods();
        return methods.items;
      },
      versionsLoaded() {
        return !!this.versionOptions 
      },
      hasVersions() {
        return this.currentVersion 
      },
      startDatePickrConfig() { 
        return {
          enableTime: true, 
          dateFormat: 'Y-m-d H:i:S', 
          altFormat: 'F j, Y - H:i:S', 
          allowInput: true, 
          altInput: true,
          maxDate: this.schedulerInfo.end_date,
          enableSeconds: true, 
          time_24hr: true	
        }
      },
      endDatePickrConfig() {
        return {
          enableTime: true,
          dateFormat: 'Y-m-d H:i:S',
          altFormat: 'F j, Y - H:i:S',
          allowInput: true,
          altInput: true,
          minDate: this.schedulerInfo.start_date,
          enableSeconds: true,
          time_24hr: true
        } 
      },
      versionOptions() {
        return this.getScheduleVersions(this.transmissionID, this.schedulerID)
      },
      userCantEdit(){
        return !this.$can('create')
      },
    },
    mounted() {
      this.init()
    },
    watch: {
      visibleExecutionLog() {
        location.href= `#${this.visibleExecutionLog}`;
      }
    },
    methods: {
      init() {
        this.getSchedulerByID()
      },
      getSchedulerByID() {
        this.$store.dispatch('getSchedulerByID', { schedulerID: this.schedulerID, transmissionID: this.transmissionID }).then((resp) => {
          if (resp.data.event) {
            resp.data.body = JSON.parse(resp.data.body)
          }
          this.body = resp.data.body
          this.schedulerInfo = resp.data
          this.getCurrentVersion()
          this.getVersionsBySchedulerID()
        }).catch((error) => {
          console.error(error)
        })
      },
      getVersionsBySchedulerID() {
        this.$store
          .dispatch('getSchedulerVersionsByID', 
            { 
              transmissionID: this.transmissionID, 
              schedulerID: this.schedulerInfo.id, 
            })
          .then((resp) => {
            this.getCurrentVersion()
          })
          .catch((err) => {
            console.error(err)
          })
      },
      getCurrentVersion() {
        this.$store.dispatch('getCurrentScheduleVersion', { transmissionID: this.transmissionID, schedulerID: this.schedulerInfo.id }).then((resp) => {
          this.currentVersion = resp.data;
        }).catch((error) => {
          if (error.data.code !== 7000) {
            makeToast({
              title: this.$t(`.error.title`),
              text: this.$t(`.error.message`),
              variant: "danger",
              icon: "XIcon",
            })
          }
        })
      },
      highlighter(code) {
        return highlight(code, languages.xml);
      },
      openPublishVersion() {
        this.$root.$emit('bv::show::modal', this.getID('publish-version'))
      },
      toggleDashboard() {
        this.$root.$emit('bv::show::modal', this.getID('dash-modal'))
      },
      toggleVersions() {
        this.$root.$emit('bv::show::modal', this.getID('versions-list'))
      },
      toggleExecutionLog() {
        this.visibleExecutionLog = this.visibleExecutionLog == '' ? 'execution-log' : '';
      },
      toggleActive() {
        const i18n_base = "scheduler.modal.confirm_activate";

        this.loadingActive = true
        
        this.$bvModal
          .msgBoxConfirm(
            this.$t(`${i18n_base}.message`, {
              schedule: this.schedulerInfo.name,
              term: this.schedulerInfo.active
                ? this.$t("common.terms.inactive")
                : this.$t("common.terms.active"),
            }),
            {
              title: this.$t(`${i18n_base}.title`),
              size: "sm",
              okVariant: "primary",
              okTitle: this.$t("common.terms.yes"),
              cancelTitle: this.$t("common.terms.no"),
              cancelVariant: "outline-secondary",
              hideHeaderClose: false,
              centered: true,
            }
          )
          .then((value) => {
            if (value) {
              this.$store
                .dispatch("toggleScheduleActive", {
                  new_value: !this.schedulerInfo.active,
                  transmissionID: this.transmissionID,
                  id: this.schedulerInfo.id,
                })
                .then(() => {
                  this.$set(this.schedulerInfo, 'active', !this.schedulerInfo.active);
                  this.loadingActive = false
                })
                .catch((err) => {
                  console.error(err)
                  this.loadingActive = false
                  
                  makeToast({
                    title: this.$t(
                      `${i18n_base}.error.title`
                    ),
                    text: this.$t(
                      `${i18n_base}.error.message`
                    ),
                    variant: "danger",
                    icon: "XIcon",
                  });
                });
            } else {
              this.loadingActive = false
            }
          });
      },
      toggleFavorite() {
        const i18n_base = "scheduler.toast.change_favorite";
        this.loadingFavorite = true

        this.$store.dispatch("toggleScheduleFavorite", {
            id: this.schedulerInfo.id,
            transmissionID: this.transmissionID,
            new_value: !this.schedulerInfo.favorite,
          })
          .then(() => {
            this.loadingFavorite = false
            this.$set(this.schedulerInfo, 'favorite', !this.schedulerInfo.favorite);
          })
          .catch(() => {
            makeToast({
              title: this.$t(
                `${i18n_base}.error.title`
              ),
              text: this.$t(
                `${i18n_base}.error.message`
              ),
              variant: "danger",
              icon: "XIcon",
            });

            this.loadingFavorite = false
          });
      },
      toggleDebugMode() {
        const i18n_base = "scheduler.modal.confirm_debug_mode";

        this.$bvModal
          .msgBoxConfirm(
            this.$t(`${i18n_base}.message`, {
              schedule: this.schedulerInfo.name,
              state: this.schedulerInfo.debug_mode_active
                ? this.$t("common.terms.on")
                : this.$t("common.terms.off"),
            }),
            {
              title: this.$t(`${i18n_base}.title`),
              size: "sm",
              okVariant: "primary",
              okTitle: this.$t("common.terms.yes"),
              cancelTitle: this.$t("common.terms.no"),
              cancelVariant: "outline-secondary",
              hideHeaderClose: false,
              centered: true,
            }
          )
          .then((value) => {
            if (value) {
              this.$store
                .dispatch("patchScheduler", {
                  field: "debug_mode_active",
                  new_value: this.schedulerInfo.debug_mode_active,
                  transmissionID: this.transmissionID,
                  schedulerID: this.schedulerInfo.id,
                })
                .then(() => {
                })
                .catch((err) => {
                  
                  makeToast({
                    title: this.$t(
                      `${i18n_base}.error.title`
                    ),
                    text: this.$t(
                      `${i18n_base}.error.message`
                    ),
                    variant: "danger",
                    icon: "XIcon",
                  });
                });
            } else {
              this.schedulerInfo.debug_mode_active = !this.schedulerInfo.debug_mode_active
            }
          });
      },
      getEventByService() {
        this.$store
          .dispatch("getEventsByService", {
            transmissionID: this.transmissionID,
            serviceId: this.schedulerInfo.service.id,
          })
          .then((response) => {
            this.eventOptions = response.reduce((previous, current) => {
              if (current.can_send) {
                previous.push(current);
              }
              return previous;
            }, []);
          })
          .catch(() => {
            makeToast({
              title: this.$t(`default_changer.toast.submit.error.title`),
              text: this.$t(`default_changer.toast.submit.error.text`),
              variant: "danger",
              icon: "XIcon",
            });
          });
      },
      getServicesByEvent() {
        if (this.service) return
        this.$store
          .dispatch("getServicesByEvent", {
            transmissionID: this.$route.params.transmissionID,
            eventId: this.schedulerInfo.event.id,
          })
          .then((response) => {
            this.serviceOptions = response.reduce((previous, current) => {
              if (current.can_receive) {
                previous.push(current);
              }
              return previous;
            }, []);
          })
          .catch((err) => {
            makeToast({
              title: this.$t(`default_changer.toast.submit.error.title`),
              text: this.$t(`default_changer.toast.submit.error.text`),
              variant: "danger",
              icon: "XIcon",
            });
          });
      },
      getWebhookByTransmission() {
        this.$store
          .dispatch("getWebhookByTransmission", {
            transmissionID: this.transmissionID,
          })
          .then((response) => {
            this.webhookOptions = response.items || [];
          })
          .catch((error) => {
            makeToast({
              title: this.$t(`default_changer.toast.submit.error.title`),
              text: this.$t(`default_changer.toast.submit.error.text`),
              variant: "danger",
              icon: "XIcon",
            });
          });
      },
      publishVersion() {
        const i18n_base = "scheduler.modal.publish_version"

        if (this.versionName) {

          this.loadingPublish = true

          this.$store
            .dispatch("insertScheduleScript", {
              transmissionID: this.transmissionID,
              schedulerID: this.schedulerInfo.id,
              name: this.versionName
            })
            .then((resp) => {
              this.getCurrentVersion()

              if(this.$refs[this.getID('versions-list')]) this.$refs[this.getID('versions-list')].init()
              
              this.loadingPublish = false
              makeToast({
                title: this.$t(
                  `${i18n_base}.success.title`
                ),
                text: this.$t(
                  `${i18n_base}.success.message`
                ),
                variant: "success",
                icon: "CheckIcon",
              });
            })
            .catch((err) => {
              console.error(err)
              this.loadingPublish = false

              makeToast({
                title: this.$t(
                  `${i18n_base}.error.title`
                ),
                text: this.$t(
                  `${i18n_base}.error.message`
                ),
                variant: "danger",
                icon: "XIcon",
              });
            });
        }
      },
      deleteSchedule() {
        const i18n_base = "scheduler.modal.confirm_delete"
        this.loadingDelete = true

        this.$bvModal
          .msgBoxConfirm(
            this.$t(`${i18n_base}.message`, {
              schedule: this.schedulerInfo.name,
            }),
            {
              title: this.$t(`${i18n_base}.title`),
              size: "sm",
              okVariant: "primary",
              okTitle: this.$t("common.terms.yes"),
              cancelTitle: this.$t("common.terms.no"),
              cancelVariant: "outline-secondary",
              hideHeaderClose: false,
              centered: true,
            }
          )
          .then((value) => {
            if (value) {
              this.$store
                .dispatch("deleteSchedule", {
                  transmissionID: this.transmissionID,
                  id: this.schedulerInfo.id,
                })
                .then(() => {        
                  this.currentVersion = null

                  this.getCurrentVersion()
                  
                  this.loadingDelete = false

                  this.$router.go(-1)
                  makeToast({
                    title: this.$t(
                      `${i18n_base}.success.title`
                    ),
                    text: this.$t(
                      `${i18n_base}.success.message`
                    ),
                    variant: "success",
                    icon: "CheckIcon",
                  });
                })
                .catch(() => {
                  this.loadingDelete = false

                  makeToast({
                    title: this.$t(
                      `${i18n_base}.error.title`
                    ),
                    text: this.$t(
                      `${i18n_base}.error.message`
                    ),
                    variant: "danger",
                    icon: "XIcon",
                  });
                });
            } else {
              this.$set(this.loadingDelete, item.id, false);
            }
          });
      },
      toggleAddItem(type) {
        this.$root.$emit('bv::toggle::collapse', this.getID(`${type}-sidebar`))
      },
      addItems(items, addFunction) {
        items.forEach((value) => {
          if (value.key || value.value) {
            this.$store.dispatch(addFunction, { transmissionID: this.transmissionID, schedulerID: this.schedulerInfo.id, item: value, }).then((resp) => {
              this.schedulerInfo[resp.field].push(resp.data)
            })
          }
        })
      },
      deleteItem(type, item, index) {
        const i18n_base = `scheduler.table.${type}.confirm_delete`
        this.loadingDelete = true

        this.$bvModal
          .msgBoxConfirm(
            this.$t(`${i18n_base}.message`),
            {
              title: this.$t(`${i18n_base}.title`),
              size: "sm",
              okVariant: "primary",
              okTitle: this.$t("common.terms.yes"),
              cancelTitle: this.$t("common.terms.no"),
              cancelVariant: "outline-secondary",
              hideHeaderClose: false,
              centered: true,
            }
          )
          .then((value) => {
            if (value) {
              this.$store.dispatch('deleteWebhookSchedulerInfo', { transmissionID: this.transmissionID, itemID: item.id, type}).then((resp) => {
                this.schedulerInfo[type == 'scheduler_header' ? 'headers' : 'queries' ].splice(index, 1)
              })
            } 
          })
      },
      patchBody() {
        if (this.body == this.schedulerInfo.body) return
        const i18n_base = "scheduler.modal.body"

        this.$store
          .dispatch("patchScheduler", {
            field: "body",
            new_value: JSON.stringify(this.body),
            transmissionID: this.transmissionID,
            schedulerID: this.schedulerInfo.id,
          })
          .then(() => {
            makeToast({
              title: this.$t(
                `${i18n_base}.success.title`
              ),
              text: this.$t(
                `${i18n_base}.success.message`
              ),
              variant: "success",
              icon: "CheckIcon",
            });
          })
          .catch(() => {
            this.body = this.schedulerInfo.body
            makeToast({
              title: this.$t(
                `${i18n_base}.error.title`
              ),
              text: this.$t(
                `${i18n_base}.error.message`
              ),
              variant: "danger",
              icon: "XIcon",
            });
          });
      },
      minuteToDay(value) {
        return (parseInt(value/24/60) > 9 ? parseInt(value/24/60) : '0' + parseInt(value/24/60)) 
          + '' + 
          (parseInt(value/60%24) > 9 ? parseInt(value/60%24) : '0' + parseInt(value/60%24)) 
          + '' + 
          (parseInt(value%60) > 9 ? parseInt(value%60) : '0' + parseInt(value%60));
      },
      dayToMinute(value) { 
        let days = parseInt(value.substring(0, 2))
        let hours = parseInt(value.substring(4, 6))
        let minutes =  parseInt(value.substring(8, 10))

        let totalMinutes = (days * 1440) + (hours * 60) + minutes
        return totalMinutes
      },
      formatDate(value) {
        if (!value) return null
        let date = new Date(value)
        return moment(date).format(this.$t('scheduler.view.moment_date_format'));
      },
      getID(key) {
        if (this.uuidMap[key]) {
          return this.uuidMap[key];
        }

        const uuid = uuidv4();
        this.uuidMap[key] = uuid;

        return uuid;
      },
      toggleBodyVisibility() {
        this.bodyVisible = !this.bodyVisible
      },
      getHash() {
        return location.hash;
      }
    }
  }
</script>

<style lang="scss" scoped>
.form-control-plaintext{
  color: rgb(202, 202, 202)
}

.b-card-info {
  min-height: 600px !important;
}

.badge-toggler {
  transition: all 0.2s ease-in-out !important;
  width: 100px !important;
}

.badge-padding-fix {
  padding-top: 0.43rem !important;
  padding-bottom: 0.43rem !important;
}

.version-badge {
  &:hover {
    opacity: 0.8 !important;
    transition: all 0.1s ease-in-out !important;
  }
}

</style>

<style lang="scss">

.body-filter {
  .prism-editor__container, .ace_text-layer {
      filter: blur(4px) !important;
  }
}
</style>